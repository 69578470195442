@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  box-sizing: content-box;
  border-width: 2px;
  border-style: solid;
  /* border-image: linear-gradient(to right bottom, #0cadda, #a053df); */
  border-image-slice: 1;
  border-radius: 10px;
  perspective: 1000px;
  box-shadow: 2px 4px 8px rgba(255, 255, 255, 0.6);
}

.circle-container.flipped {
  transform: rotateY(180deg);
  transition: transform 0.5s ease;
}

.circle-container.flipped .flipped-div {
  transform: rotateY(180deg);
}

.circle-container.metaphor {
  transform: rotateY(360deg);
  transition: transform 0.5s ease;
}

.circle-container.metaphor .metaphor-div {
  transform: rotateY(360deg);
}

.abi-text {
  font-weight: 700;
  font-size: 35px;
  /* background-image: linear-gradient(to right bottom, #0cadda, #a053df); */
  background-clip: text;
  text-align: center;
  position: relative;
  top: 20px;
}

.circle {
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 100px auto;
  /* animation: spin 5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards; */
}

.dot {
  position: absolute;
  width: 3px; /* Size of the dot */
  height: 3px; /* Size of the dot */
  background-color: #fff;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform-origin: 0 -25px; /* Half of the circle's radius */
}

.center-image {
  /* animation: spin 5s cubic-bezier(0.22, 0.61, 0.36, 1) forwards; */
}

.dot.highlighted {
  /* background-color: blue; */
  width: 10px;
  height: 10px;
  transition: width 0.5s ease, height 0.5s ease;
  z-index: 1;
}

.flipped-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.selected-number {
  font-size: 35px;
  text-align: center;
  opacity: 1;
  transition: font-size 0.5s ease;
  position: relative;
  bottom: 70px;
}

.proceed-button {
  font-size: 25px;
  padding: 5px;
  text-align: center;
  opacity: 1;
  transition: font-size 0.5s ease;
  position: relative;
  bottom: 70px;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}

blockquote {
  font-size: 18px;
  font-style: italic;
  margin: 0.25em 0;
  padding: 0.35em 40px;
  line-height: 1.45;
  position: relative;
  color: #fff;
}

blockquote:before {
  display: block;
  padding-left: 10px;
  content: "\201C";
  font-size: 80px;
  position: absolute;
  left: 0px;
  top: -20px;
  color: #fff;
}

blockquote cite {
  color: #fff;
  font-size: 14px;
  display: block;
  margin-top: 5px;
}

blockquote cite:before {
  content: "\2014 \2009";
}

/* .arrow {
  position: relative;
  width: 50px;
  height: 50px; 
} */

.top-right {
  /* top: 65px;
  left: 80px; */
  transform: scale(1.4) rotate(195deg);
}

.top-left {
  /* top: 650px;
  left: 70px; */
  transform: scale(1.4) rotate(90deg);
}

.bottom-left {
  /* bottom: -95px;
  left: -85px; */
  transform: scale(1.4);
}

.bottom-right {
  /* bottom: -95px;
  right: -85px; */
  transform: scale(1.4) rotate(-90deg);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(2880deg);
  }
}

@media only screen and (max-width: 600px) {
  .dot {
  }
}
